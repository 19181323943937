<template>
  <div id="app">
   <!-- <SiteNavbar/>-->
    <router-view/>
    <!-- <SiteFooter/>-->
  </div>
</template>

<script>
//import SiteNavbar from './components/SiteNavbar.vue';
//import SiteFooter from './components/SiteFooter.vue';

export default {
  name: 'App',
  components: {
    //SiteNavbar,
    //SiteFooter
  }
}
</script>

<style>
/* Agrega aquí tus estilos globales si es necesario */
</style>
