<template>
    <div class="site-blocks-cover inner-page-cover  overlay" :style="{ backgroundImage: 'url(' + require('@/assets/img_1.jpg') + ')' } " data-aos="fade"
      data-stellar-background-ratio="0.5"  >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 text-center" data-aos="fade-up" data-aos-delay="400">
            <h1 class="mb-4">Nosotros</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="site-section">
        <div class="container-fluid w-75">
            <div class="row justify-content-center mb-5">
            </div>
            <div class="row">
                <div v-for="(lawyer, index) in lawyers" :key="index" class="col-lg-3 col-md-6 mb-4 mb-lg-0">
                    <div class="person-1">
                        <img :src="lawyer.image" alt="Image" class="img-fluid">
                        <div class="person-1-contents">
                            <h2>{{ lawyer.name }}</h2>
                            <span class="d-block position">{{ lawyer.position }}</span>
                            <div class="person-1-social">
                              Ver mas
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AbogadosView',
    data() {
        return {
            lawyers: [
                {
                    name: 'German Pereira Dos Santos',
                    position: 'Founder',
                    image: 'https://placehold.co/600x600',
                     
                },
                {
                    name: 'Silvina Natalia Igolmikov',
                    position: 'Founder',
                    image: 'https://placehold.co/600x600',
                    
                },
                {
                    name: 'Clara Susana Gelman',
                    position: 'Founder',
                    image: 'https://placehold.co/600x600',
                      
                    
                },
                {
                    name: 'Fabiana Quani',
                    position: 'Founder',
                    image: 'https://placehold.co/600x600',
                     
                }
            ]
        }
    }
}
</script>

<style scoped>
/* Agrega aquí tus estilos personalizados si es necesario */
</style>