<template>
  <div class="site-blocks-cover inner-page-cover  overlay" :style="{ backgroundImage: 'url(' + require('@/assets/hero_2.jpg') + ')' } " data-aos="fade"
      data-stellar-background-ratio="0.5"  >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 text-center" data-aos="fade-up" data-aos-delay="400">
            <h1 class="mb-4">CONTACTO</h1>
             
            <!-- <p><a href="#" class="btn btn-primary px-4 py-3">Comenzar</a></p> -->
          </div>
        </div>
      </div>
    </div>
    
  <div class="site-section">
    <div class="container-fluid w-75">
      <div class="row">

        <!-- Contact Form -->
        <div class="col-md-12 col-lg-7 mb-5">
          <form @submit.prevent="submitForm" class="contact-form">
            <div class="row form-group">
              <div class="col-md-12 mb-3 mb-md-0">
                <label class="font-weight-bold" for="fullname">Nombre Completo</label>
                <input v-model="form.fullname" type="text" id="fullname" class="form-control" placeholder="Nombre Completo" required>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <label class="font-weight-bold" for="email">Email</label>
                <input v-model="form.email" type="email" id="email" class="form-control" placeholder="Correo Electrónico" required>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <label class="font-weight-bold" for="subject">Asunto</label>
                <input v-model="form.subject" type="text" id="subject" class="form-control" placeholder="Ingrese Asunto" required>
              </div>
            </div>

            <div class="row form-group">
              <div class="col-md-12">
                <label class="font-weight-bold" for="message">Mensaje</label>
                <textarea v-model="form.message" id="message" cols="30" rows="5" class="form-control" placeholder="Salúdenos" required></textarea>
              </div>
            </div>

            <div class="row form-group">
              <div class="col-md-12">
                <input type="submit" value="Enviar Mensaje" class="btn btn-primary py-3 px-4">
              </div>
            </div>
          </form>
        </div>

        <!-- Contact Information -->
        <div class="col-lg-4 ml-auto">
          <div class="p-4 mb-3 bg-white">
            <h3 class="h5 text-black mb-3">Información de Contacto</h3>
            <p class="mb-0 font-weight-bold text-black">Dirección</p>
            <p class="mb-4 text-black">Montevideo 665, Piso 7, Oficina 708<br>
              Ciudad de Buenos Aires, 1019<br>
              Argentina</p>

            <p class="mb-0 font-weight-bold text-black">Teléfono</p>
            <p class="mb-4"><a href="tel:+541112345678">+54 11 1234 5678</a></p>

            <p class="mb-0 font-weight-bold text-black">Correo Electrónico</p>
            <p class="mb-0"><a href="mailto:contacto@baireslegal.com">contacto@baireslegal.com</a></p>

            <p class="mb-0 font-weight-bold text-black">WhatsApp</p>
            <p class="mb-0"><a href="https://wa.me/5491125070295" target="_blank">+54 9 11 2507-0295</a></p>

            <p class="mb-0 font-weight-bold text-black">Sitio Web</p>
            <p class="mb-0"><a href="https://www.baireslegal.com" target="_blank">www.baireslegal.com</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactView',
  data() {
    return {
      form: {
        fullname: '',
        email: '',
        subject: '',
        message: ''
      }
    }
  },
  methods: {
    submitForm() {
      // Aquí puedes agregar la lógica para enviar el formulario
      console.log('Formulario enviado:', this.form);
    }
  }
}
</script>

<style scoped>
/* Agrega aquí tus estilos personalizados si es necesario */
</style>
