<template>
  
    <div class="site-blocks-cover overlay" style="background-color: black;" data-aos="fade"
      data-stellar-background-ratio="0.5"  >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 text-center" data-aos="fade-up" data-aos-delay="400">
            <img  class="img-fluid" src="@/assets/logo2.png" alt="Logo">
            <p class="mb-5">Un solo lugar para todos sus asuntos legales, con especialistas en cada área.</p>
            <!-- <p><a href="#" class="btn btn-primary px-4 py-3">Comenzar</a></p> -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HeroSection'
  }
  </script>
  
  <style scoped>
  /* Agrega aquí tus estilos personalizados si es necesario */
  </style>
  