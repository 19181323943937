<template>
  <HeroSection/>
  <!--<AreasSection/>-->
</template>

 
<script>

import HeroSection from '../components/HeroSection.vue';
//import AreasSection from '../components/AreasSection.vue';

export default {
  name: 'HomeView',
  components: {
    HeroSection,
    //AreasSection
  }
}
</script>

